import { redirect, type MetaFunction } from "react-router";

export const meta: MetaFunction = () => {
  return [
    { title: "Assessain.com" },
    {
      name: "description",
      content:
        "Your go-to platform for creating and sharing educational assessments.",
    },
  ];
};

export const loader = async () => {
  return redirect("/p/", 301);
};
